<nav class="navbar navbar-expand-lg bg-white">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/">Administrador</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li *ngIf="accountConnected" class="nav-item">
          <a class="nav-link active" aria-current="page" routerLink="/dashboard">Dashboard</a>
        </li>
        <li *ngIf="accountConnected" class="nav-item">
          <a class="nav-link" routerLink="/cuentas">Cuentas</a>
        </li>
        <li *ngIf="!accountConnected" class="nav-item">
          <a class="nav-link" routerLink="/login">Iniciar Sesión</a>
        </li>
        <li *ngIf="accountConnected" class="nav-item">
          <a class="nav-link" style="cursor: pointer;" (click)="logOut()">Cerrar Sesión</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
