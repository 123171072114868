import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecoverPasswordGuard } from './shared/guards/recover-password.guard';
import { AdminGuard } from './shared/guards/admin.guard';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch:'full'},
  {
    path: 'recover-password/:id',
    canActivate: [RecoverPasswordGuard],
    loadChildren:() => import('./pages/recover-password/recover-password.module').then(m => m.RecoverPasswordModule)
  },
  {path: 'login',
  loadChildren:() => import('./pages/login/login.module').then(m => m.LoginModule)},
  {path: 'dashboard',
    loadChildren:() => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)},
  {path: 'cuentas', canActivate: [AdminGuard],
  loadChildren:() => import('./pages/accounts-dashboard/accounts-dashboard.module').then(m => m.AccountsDashboardModule)},
  {path: 'unauthorized',
  loadChildren:() => import('./pages/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule)},
  { path: '**',
  loadChildren:() => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
