import { Component, OnDestroy, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { AdminService } from '../admin.service';
import { CookieService } from 'ngx-cookie-service';
import { AccountCountInterface } from '../../shared/interfaces/account-count.interface';
import { Subscription } from 'rxjs';
import { GetCountAccountsInterface } from '../../shared/interfaces/get-count-accounts.interface';
import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';
import { DayCountInterface } from '../../shared/interfaces/day-count.interface';
import { GetCountDayInterface } from '../../shared/interfaces/get-count-day.interface';
Highcharts.setOptions({
  lang: {
    months: [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ],
    shortMonths: [
      'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
      'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
    ]
  }
});

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  //Account Count
  selectedRole?: string;
  startDateAccount: string | null = null;
  endDateAccount: string | null = null;
  accountsCountUsb?: Subscription;
  //HighChart
  isHighchartsAccount = typeof Highcharts === 'object';
  HighchartsAccount: typeof Highcharts = Highcharts;
  chartConstructorAccount: string = 'chart';
  chartOptionsAccount: Highcharts.Options = {
    series: [],
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%B %Y',
        year: '%B %Y'
      },
      tickPositions: [],
    },
    yAxis: {
      title: {
        text: 'Cantidad de usuarios',
      },
    },
    title: {
      text: 'Cantidad de usuarios por mes',
    },
  };
  updateFlagAccount: boolean = false;
  oneToOneFlagAccount: boolean = true;
  runOutsideAngularAccount: boolean = false;
  isLoadingValuesAccount: boolean = true;
  valuesDisplaysAccount: boolean = false;
  valuesEmptyAccount: boolean = false;

  //Day Count
  startDateDay: string | null = null;
  endDateDay: string | null = null;
  daysCountUsb?: Subscription;
  //HighChart
  isHighchartsDay = typeof Highcharts === 'object';
  HighchartsDay: typeof Highcharts = Highcharts;
  chartConstructorDay: string = 'chart';
  chartOptionsDay: Highcharts.Options = {
    series: [],
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%B %Y',
        year: '%B %Y'
      },
      tickPositions: [],
    },
    yAxis: {
      title: {
        text: 'Cantidad de viajes completados',
      },
    },
    title: {
      text: 'Cantidad de viajes completados por mes',
    },
  };
  updateFlagDay: boolean = false;
  oneToOneFlagDay: boolean = true;
  runOutsideAngularDay: boolean = false;
  isLoadingValuesDay: boolean = true;
  valuesDisplaysDay: boolean = false;
  valuesEmptyDay: boolean = false;

  constructor(
    private adminService: AdminService,
    private cookieService: CookieService,
  ) {}

  ngOnInit(): void {
    const accountBody: GetCountAccountsInterface = {};
    this.accountsCountUsb = this.adminService.getAccountsCount(accountBody).subscribe(accountsCount => {
      this.chartOptionsAccount.series = this.transformDataValuesAccount(accountsCount);
      (this.chartOptionsAccount.xAxis as Highcharts.XAxisOptions).tickPositions = this.getUniqueDates(accountsCount);
      this.updateFlagAccount = true;
      this.isLoadingValuesAccount = false;
      this.valuesDisplaysAccount = accountsCount.length > 0;
      this.valuesEmptyAccount = accountsCount.length === 0;
    });

    const dayBody: GetCountDayInterface = {};
    this.daysCountUsb = this.adminService.getDaysCount(dayBody).subscribe(daysCount => {
      this.chartOptionsDay.series = this.transformDataValuesDay(daysCount);
      (this.chartOptionsDay.xAxis as Highcharts.XAxisOptions).tickPositions = this.getUniqueDatesDay(daysCount);
      this.updateFlagDay = true;
      this.isLoadingValuesDay = false;
      this.valuesDisplaysDay = daysCount.length > 0;
      this.valuesEmptyDay = daysCount.length === 0;
    });
  }

  //Account

  transformDataValuesAccount(data: AccountCountInterface[]): Highcharts.SeriesOptionsType[] {
    const groupedData: { [key: string]: { x: number, y: number }[] } = {};
    data.forEach((item) => {
      const roleName = item.roleName;
      if (!groupedData[roleName]) {
        groupedData[roleName] = [];
      }
      item.counts.forEach((accountCount) => {
        groupedData[roleName].push({
          x: accountCount.date,
          y: accountCount.count,
        });
      })
    });

    const transformedData: Highcharts.SeriesOptionsType[] = Object.keys(groupedData).map((roleName) => ({
      name: roleName,
      data: groupedData[roleName],
      type: 'line',
    }));
    return transformedData;
  }

  setCurrentRole(roleName: string) {
    this.selectedRole = roleName;
  }

  getAccountsCount(form: NgForm) {
    let startDate, endDate;
    this.updateFlagAccount = false;
    this.isLoadingValuesAccount = true;
    this.valuesDisplaysAccount = false;
    this.valuesEmptyAccount = false;
    const accountBody:GetCountAccountsInterface = form.value;
    if(accountBody.startingDate && accountBody.endingDate) {
      startDate = formatDate(accountBody.startingDate, 'yyyy-MM-dd', 'en-US');
      endDate = formatDate(accountBody.endingDate, 'yyyy-MM-dd', 'en-US');
      accountBody.startingDate = startDate;
      accountBody.endingDate = endDate;
    } else if (this.startDateAccount && this.endDateAccount) {
      startDate = formatDate(this.startDateAccount, 'yyyy-MM-dd', 'en-US');
      endDate = formatDate(this.endDateAccount, 'yyyy-MM-dd', 'en-US');
      accountBody.startingDate = startDate;
      accountBody.endingDate = endDate;
    }
    accountBody.roleName = this.selectedRole;
    this.accountsCountUsb = this.adminService.getAccountsCount(accountBody).subscribe(accountsCount => {
      this.chartOptionsAccount.series = this.transformDataValuesAccount(accountsCount);
      (this.chartOptionsAccount.xAxis as Highcharts.XAxisOptions).tickPositions = this.getUniqueDates(accountsCount);
      this.updateFlagAccount = true;
      this.isLoadingValuesAccount = false;
      this.valuesDisplaysAccount = accountsCount.length > 0;
      this.valuesEmptyAccount = accountsCount.length === 0;
      this.selectedRole = undefined;
    });
  }

  getUniqueDates(data: AccountCountInterface[]): number[] {
    const dateSet = new Set<number>();
    data.forEach((item) => {
      item.counts.forEach((accountCount) => {
        dateSet.add(accountCount.date);
      });
    });
    return Array.from(dateSet).sort();
  }

  //Days

  transformDataValuesDay(data: DayCountInterface[]): Highcharts.SeriesOptionsType[] {
    const groupedData: { x: number, y: number }[]  = [];
    data.forEach((item) => {
      groupedData.push({
        x: item.date,
        y: item.count,
      })
    });
    const transformedData: Highcharts.SeriesOptionsType[] = [
      {
        name: 'Viajes Completados',
        data: groupedData,
        type: 'line',
      }
    ];
    return transformedData;
  }

  getUniqueDatesDay(data: DayCountInterface[]): number[] {
    const dateSet = new Set<number>();
    data.forEach((item) => {
      dateSet.add(item.date);
    });
    return Array.from(dateSet).sort();
  }

  getDaysCount(form: NgForm) {
    let startDate, endDate;
    this.updateFlagDay = false;
    this.isLoadingValuesDay = true;
    this.valuesDisplaysDay = false;
    this.valuesEmptyDay = false;
    const dayBody:GetCountDayInterface= form.value;
    if(dayBody.startingDate && dayBody.endingDate) {
      startDate = formatDate(dayBody.startingDate, 'yyyy-MM-dd', 'en-US');
      endDate = formatDate(dayBody.endingDate, 'yyyy-MM-dd', 'en-US');
      dayBody.startingDate = startDate;
      dayBody.endingDate = endDate;
    } else if (this.startDateDay && this.endDateDay) {
      startDate = formatDate(this.startDateDay, 'yyyy-MM-dd', 'en-US');
      endDate = formatDate(this.endDateDay, 'yyyy-MM-dd', 'en-US');
      dayBody.startingDate = startDate;
      dayBody.endingDate = endDate;
    }
    this.daysCountUsb = this.adminService.getDaysCount(dayBody).subscribe(daysCount => {
      this.chartOptionsDay.series = this.transformDataValuesDay(daysCount);
      (this.chartOptionsDay.xAxis as Highcharts.XAxisOptions).tickPositions = this.getUniqueDatesDay(daysCount);
      this.updateFlagDay = true;
      this.isLoadingValuesDay = false;
      this.valuesDisplaysDay = daysCount.length > 0;
      this.valuesEmptyDay = daysCount.length === 0;
    });
  }


  ngOnDestroy(): void {
    if (this.accountsCountUsb) {
      this.accountsCountUsb.unsubscribe();
    }

    if (this.daysCountUsb) {
      this.daysCountUsb.unsubscribe();
    }
  }
}
