import { Injectable } from '@angular/core';
import { ErrorHandlingService } from '../../shared/error-handling.service';
import { HttpClient } from '@angular/common/http';
import { RecoverPasswordAccount } from './interfaces/recover-password-account.interface';
import { AccountInterface } from '../../shared/interfaces/account.interface';
import { ValidatedVerificationTokenInterface } from './interfaces/validated-verification-token.interface';
import { environment } from '../../../environments/environment';
import { Observable, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecoverPasswordService {

  constructor(private http:HttpClient, private errorHandlingService:ErrorHandlingService) { }

  recoverPassword(accountId: string, accountData:RecoverPasswordAccount):Observable<AccountInterface> {
    return this.http.put<any>(`${environment.apiUrl}account/recover-password/${accountId}`, accountData).pipe(catchError(this.errorHandlingService.handleError));
  }

  validateVerificationToken(accountId:string, token:string):Observable<ValidatedVerificationTokenInterface>{
    return this.http.get<any>(`${environment.apiUrl}account/validate-recover-token/${accountId}?recover-token=${token}`)
  }

}
