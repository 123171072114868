import { Injectable } from '@angular/core';
import { RoleInterface } from '../shared/interfaces/role.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorHandlingService } from '../shared/error-handling.service';
import { catchError, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AccountsPaginatedInterface } from '../shared/interfaces/accounts-paginated.interface';
import { AccountInterface } from '../shared/interfaces/account.interface';
import { AccountRoleInterface } from '../shared/interfaces/account-role.interface';
import { UpdateAccountInterface } from '../shared/interfaces/update-account.interface';
import { GetCountAccountsInterface } from '../shared/interfaces/get-count-accounts.interface';
import { GetCountDayInterface } from '../shared/interfaces/get-count-day.interface';
import { DayCountInterface } from '../shared/interfaces/day-count.interface';
import { AccountCountInterface } from '../shared/interfaces/account-count.interface';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  getJwt() {
    return this.cookieService.get('account-token');
  }

  getHeaders() {
    const jwt = this.getJwt();
    return new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
  }

  constructor(
    private http: HttpClient,
    private errorHandlingService: ErrorHandlingService,
    private cookieService: CookieService,
  ) {}

  //Day
  getDaysCount(body: GetCountDayInterface): Observable<DayCountInterface[]> {
    return this.http
     .post<any>(`${environment.apiUrl}day/get-count`, body, {headers: this.getHeaders()}).pipe(catchError(this.errorHandlingService.handleError));
  }

  //Role
  getAllRoles(): Observable<RoleInterface[]> {
    return this.http
      .get<any>(`${environment.apiUrl}role`, {headers: this.getHeaders()})
      .pipe(catchError(this.errorHandlingService.handleError));
  }

  //Accounts

  getAccountById(accountId: string): Observable<AccountInterface> {
    return this.http
      .get<any>(`${environment.apiUrl}account/${accountId}`)
      .pipe(catchError(this.errorHandlingService.handleError));
  }

  getAccountsCount(body: GetCountAccountsInterface): Observable<AccountCountInterface[]> {
    return this.http
     .post<any>(`${environment.apiUrl}account/get-count`, body, {headers: this.getHeaders()}).pipe(catchError(this.errorHandlingService.handleError));
  }

  getAllAccounts(page: string, pageSize: string): Observable<AccountsPaginatedInterface> {
    return this.http
      .get<any>(`${environment.apiUrl}account?page=${page}&pageSize=${pageSize}`, {headers: this.getHeaders()})
      .pipe(catchError(this.errorHandlingService.handleError));
  }

  getAccountByRole(page: string, pageSize: string, roleName: string): Observable<AccountsPaginatedInterface> {
    return this.http
      .get<any>(`${environment.apiUrl}account/by-role?page=${page}&pageSize=${pageSize}&roleName=${roleName}`, {headers: this.getHeaders()})
      .pipe(catchError(this.errorHandlingService.handleError));
  }

  getAllAccountsByUsername(page: string, pageSize: string, searchText: string): Observable<AccountsPaginatedInterface> {
    return this.http.get<any>(`${environment.apiUrl}account/by-username?page=${page}&pageSize=${pageSize}&searchText=${searchText}`, {headers: this.getHeaders()})
    .pipe(catchError(this.errorHandlingService.handleError));
  }

  getAllAccountsByEmail(page: string, pageSize: string, searchText: string): Observable<AccountsPaginatedInterface> {
    return this.http.get<any>(`${environment.apiUrl}account/by-email/paginated?page=${page}&pageSize=${pageSize}&searchText=${searchText}`, {headers: this.getHeaders()})
    .pipe(catchError(this.errorHandlingService.handleError));
  }

  activateAccount(accountId: string): Observable<AccountInterface> {
    return this.http.get<any>(`${environment.apiUrl}account/protected/activate-account/${accountId}`, {headers: this.getHeaders()}).pipe(catchError(this.errorHandlingService.handleError));
  }

  deactivateAccount(accountId: string): Observable<AccountInterface> {
    return this.http.get<any>(`${environment.apiUrl}account/protected/deactivate-account/${accountId}`, {headers: this.getHeaders()}).pipe(catchError(this.errorHandlingService.handleError));
  }

  addRoleToAccount(accountId: string, roleData: AccountRoleInterface): Observable<AccountInterface> {
    return this.http.put<any>(`${environment.apiUrl}account/protected/add-role/${accountId}`, roleData, {headers: this.getHeaders()})
      .pipe(catchError(this.errorHandlingService.handleError));
  }

  removeRoleOfAccount(accountId: string, roleData: AccountRoleInterface): Observable<AccountInterface> {
    return this.http.put<any>(`${environment.apiUrl}account/protected/delete-role/${accountId}`, roleData, {headers: this.getHeaders()})
      .pipe(catchError(this.errorHandlingService.handleError));
  }

  updateAccount(
    accountId: string,
    accountData: UpdateAccountInterface
  ): Observable<AccountInterface> {
    return this.http
      .put<any>(`${environment.apiUrl}account/${accountId}`, accountData, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.errorHandlingService.handleError));
  }

  deleteAccount(accountId: string): Observable<AccountInterface> {
    return this.http
      .delete<any>(`${environment.apiUrl}account/${accountId}`, {headers: this.getHeaders()})
      .pipe(catchError(this.errorHandlingService.handleError));
  }
}
