<section class="main-container">
  <div class="container d-flex flex-column">
    <h1 class="header text-center mb-4 py-4"> Panel de Cuentas </h1>
  </div>
  <div class="container my-5 service-container">
    <div class="container button d-flex flex-column justify-content-start mb-3">
      <div class="row mb-3">
        <form #formDataSearch="ngForm" (ngSubmit)="filterItems(formDataSearch)"  class="d-flex flex-row justify-content-between">
          <div class="input-group">
            <input type="text" class="form-control border border-1 rounded-1" placeholder="Buscar" aria-label="Search" aria-describedby="Search" ngModel name="searchText">
          </div>
          <button type="submit" class="btn custom-button-search fw-bold">Buscar</button>
        </form>
      </div>
      <div class="buttons-container">
        <div class="dropdown text-center button-dropdown">
          <button class="btn custom-button fw-bold dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{ currentRoleName ? currentRoleName : 'Buscar por rol'}}
          </button>
          <ul class="dropdown-menu">
            <li class="dropdown-item" *ngFor="let role of roles"  style="cursor: pointer;"
              (click)="filterByRole(role)">
              {{role.roleName}}
            </li>
          </ul>
        </div>
        <div class="dropdown text-center">
          <button class="btn custom-button fw-bold dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{ searchType.length > 0 ? searchType : "Seleccione un término de busqueda"}}
          </button>
          <ul class="dropdown-menu">
            <li class="dropdown-item" style="cursor: pointer;" (click)="setSearchType('Nombre de usuario'); $event.preventDefault()">
              Nombre de usuario
            </li>
            <li class="dropdown-item" style="cursor: pointer;" (click)="setSearchType('Correo electrónico'); $event.preventDefault()">
              Correo electrónico
            </li>
          </ul>
        </div>
      </div>
      <div  class="d-flex flex-row justify-content-center">
        <button class="btn custom-button fw-bold mb-4 me-2" (click)="defaultItems()">
          Obtener todas las cuentas
        </button>
      </div>
    </div>

    <div *ngIf="noItems">
      <p>No se encontró ninguna cuenta</p>
    </div>

    <div *ngIf="!noItems" class="container-table table-responsive">
        <table class="table table-hover table-bordered py-5">
            <thead >
                <tr class="table-light">
                    <th>Nombres de usuario</th>
                    <th>Email</th>
                    <th>Activo</th>
                    <th>Roles</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody class="table-hover">
                <tr *ngFor="let account of filteredItems">
                    <td class="table-bordered">
                      {{ account.username }}
                    </td>
                    <td class="table-bordered">{{ account.email }}</td>
                    <td class="table-bordered">{{ account.active ? 'Activo' : account.active === false ? 'Inactivo' : '' }}</td>
                    <td class="table-bordered">{{ returnAccountRoleNames(account) }}</td>
                    <td>
                         <button class="btn btn-secondary btn-table margin-btn" (click)="displayEditModal(account)"><i class="fa fa-pencil "></i></button>
                         <button class="btn btn-danger btn-table" (click)="displayDeleteModal(account)"><i class="fa fa-trash "></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <nav class="mb-5 d-flex justify-content-center" aria-label="pagination item history">
      <ul class="pagination">
        <li class="page-item btn-pagination" [class.disabled]="currentPage === 1">
          <a class="page-link font-pagination text-center" href="javascript:void(0);" (click)="changePage(currentPage! - 1, $event)">
            <i class="fa-solid fa-arrow-left"></i>
          </a>
        </li>
        <li class="page-item btn-pagination" *ngFor="let page of displayedPages!" [class.active]="page === currentPage!">
          <a class="page-link font-pagination text-center" href="javascript:void(0);" (click)="changePage(page, $event)">{{ page }}</a>
        </li>
        <li class="page-item btn-pagination" [class.disabled]="currentPage === totalPages">
          <a class="page-link font-pagination text-center" href="javascript:void(0);" (click)="changePage(currentPage! + 1, $event)">
            <i class="fa-solid fa-arrow-right"></i>
          </a>
        </li>
      </ul>
    </nav>

  </div>
</section>

  <!--Delete  Modal-->
  <div class="modal" tabindex="-1" [ngStyle]="{'display':showDeleteModal}">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title header" id="create-material-header">Eliminar Cuenta</h5>
              <button type="button" class="btn-close" aria-label="Close"
                  (click)="closeDeleteModal()"></button>
          </div>
          <div class="modal-body py-4 scrollable-modal-body">
              <p class="text-center fw-bold  mb-2"> ¿Estás seguro que deseas borrar la cuenta
                  {{currentAccount?.slug}}?</p>
              <p class="text-body-delete px-3">
                Si borras esta cuenta, se eliminará junto con toda su información y no habra manera de recuperarlo.
              </p>
          </div>
          <div class="modal-footer d-flex justify-content-between px-3">
              <button type="button" class="btn btn-danger fw-bold" (click)="deleteAccount()">Eliminar Cuenta</button>
              <button type="button" class="btn btn-secondary close-confirmation-modal"
                  (click)="closeDeleteModal()">Cerrar</button>
          </div>
      </div>
    </div>
  </div>

  <!--Delete Confirmation  Modal-->
  <div class="modal" tabindex="-1" [ngStyle]="{'display':displayConfirmDelete}">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title header" id="create-material-header">¡Cuenta borrada satisfactoriamente!</h5>
              <button type="button" class="btn-close" aria-label="Close"
                  (click)="closeConfirmDelete()"></button>
          </div>
          <div class="modal-body py-4 scrollable-modal-body">
              <p class="text-body-delete px-3">La Cuenta {{currentAccount?.slug}} fue borrada satisfactoriamente <br> Puede cerrar esta ventana.</p>
          </div>
          <div class="modal-footer px-3">
              <button type="button" class="btn btn-secondary close-confirmation-modal"
                  (click)="closeConfirmDelete()">Cerrar</button>
          </div>
      </div>
    </div>
  </div>

  <!--Edit Modal-->
  <div class="modal" tabindex="-1" [ngStyle]="{'display':showEditModal}">
    <div  class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title header" >Editar Cuenta</h5>
                <button type="button" class="btn-close" aria-label="Close" (click)="closeEditModal()"></button>
            </div>
            <div *ngIf="editSpinner" class="my-2 d-flex justify-content-center align-items-center">
              <app-small-spinner></app-small-spinner>
            </div>
            <div *ngIf="!editSpinner" class="modal-body py-4 scrollable-modal-body">
              <div class="alert alert-success d-flex justify-content-between" *ngIf="displayConfirmEdit">
                <span>La cuenta fue editado satisfactoriamente</span>
                <button type="button" class="btn-close text-end" aria-label="Close" (click)="closeConfirmEdit()"></button>
              </div>
              <div class="alert alert-danger d-flex justify-content-between" *ngIf="displayErrorEdit">
                <span>{{errorEdit}}</span>
                <button type="button" class="btn-close text-end" aria-label="Close" (click)="closeErrorEdit()"></button>
              </div>
              <form #formDataEdit="ngForm" (ngSubmit)="edit(formDataEdit)">
                <div class="mb-3">
                  <label for="email" class="form-label fw-bold">Correo Electrónico </label>
                  <input type="email" id="email" [ngModel]="currentAccount?.email" class="form-control" ngModel name="email">
                </div>
                <div class="mb-3">
                  <label for="username" class="form-label fw-bold">Nombre de usuario</label>
                  <input type="text" id="username" [ngModel]="currentAccount?.username" class="form-control" ngModel name="username">
                </div>
                <div class="mb-3">
                  <label for="dni" class="form-label fw-bold">DNI</label>
                  <input type="text" id="dni" [ngModel]="currentAccount?.dni" class="form-control" ngModel name="dni">
                </div>
                <div class="mb-3">
                  <label for="phoneNumber" class="form-label fw-bold">Número de celular</label>
                  <input type="text" id="phoneNumber" [ngModel]="currentAccount?.phoneNumber" class="form-control" ngModel name="phoneNumber">
                </div>
                  <div class="container-button d-flex justify-content-center">
                      <button type="submit" class="btn custom-button fw-bold">Editar Cuenta</button>
                  </div>
              </form>
              <div class="mt-3">
                <div class="alert alert-success d-flex justify-content-between" *ngIf="displayAddedRole">
                  <span>¡El role fue agregado satisfactoríamente!</span>
                  <button type="button" class="btn-close text-end" aria-label="Close" (click)="closeAddRoleConfirmation()"></button>
                </div>
                <div class="alert alert-success d-flex justify-content-between" *ngIf="displayDeleteRole">
                  <span>¡El role fue borrado satisfactoríamente!</span>
                  <button type="button" class="btn-close text-end" aria-label="Close" (click)="closeDeleteRoleConfirmation()"></button>
                </div>
                <p class="mb-1">Roles de la cuenta: {{currentRolesNames}}</p>
                <label for="categories" class="form-label">Agregar roles de cuenta</label>
                <div class="dropdown w-100">
                  <button class="btn  dropdown-toggle custom-button" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {{ "Agregar un rol"}}
                  </button>
                  <ul class="dropdown-menu scrollable-dropdown-menu">
                    <li *ngFor="let role of roles"
                      style="cursor: pointer;" (click)="addRoleToAccount(role)"><a
                        class="dropdown-item "> {{ role.roleName}}</a></li>
                  </ul>
                </div>
                <label for="categories" class="form-label mt-2">Quitar un role</label>
                <div class="dropdown w-100">
                  <button class="btn  dropdown-toggle custom-button" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {{ "Remover un rol"}}
                  </button>
                  <ul class="dropdown-menu scrollable-dropdown-menu">
                    <li *ngFor="let role of rolesServices"
                      style="cursor: pointer;" (click)="removeRoleToAccount(role)"><a
                        class="dropdown-item "> {{ role.role.roleName}}</a></li>
                  </ul>
                </div>
              </div>
              <div *ngIf="currentAccount?.active === false" class="container-button d-flex justify-content-center mt-3">
                <button type="button" class="btn custom-button fw-bold" (click)="activateAccount()">Activar Cuenta</button>
              </div>
              <div *ngIf="currentAccount?.active === true" class="container-button d-flex justify-content-center mt-3">
                <button type="button" class="btn custom-button fw-bold" (click)="deactivateAccount()">Desactivar Cuenta</button>
              </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary close-confirmation-modal"
                    (click)="closeEditModal()">Cerrar
                  </button>
            </div>
        </div>
    </div>
  </div>
