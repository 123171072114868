<div class="container">
  <h1 class="py-2 ms-4">Estadisticas</h1>
  <h5 class="py-2 ms-4">Cantidad de usuarios por mes</h5>
  <form #fAccount="ngForm" (ngSubmit)="getAccountsCount(fAccount)">
    <div class="ms-4 d-flex flex-row gap-2 mb-2">
      <div class="d-flex flex-column align-items-start gap-1">
        <span class="fw-bold span-date">Inicio</span>
        <input type="date" class="form-control form-date" ngModel name="startingDate">
      </div>
      <div class="d-flex flex-column align-items-start gap-1">
        <span class="span-date fw-bold">Fin</span>
        <input type="date" class="form-control form-date" ngModel name="endingDate">
      </div>
      <div class="dropdown">
        <button class="btn dropdown-toggle btn-custom dropdown-margin" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          {{ selectedRole ? selectedRole : 'Seleccionar tipo de usuario'}}
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" (click)="setCurrentRole('Pasajero')" >Pasajero</a></li>
          <li><a class="dropdown-item" (click)="setCurrentRole('Conductor')" >Conductor</a></li>
        </ul>
      </div>
      <button class="btn btn-custom dropdown-margin" type="submit">Buscar</button>
    </div>
  </form>

  <div *ngIf="isLoadingValuesAccount && valuesDisplaysAccount && !valuesEmptyAccount"
    class="d-flex justify-content-center mt-2 chart-container">
    <app-small-spinner></app-small-spinner>
  </div>
  <div *ngIf="valuesEmptyAccount" class="d-flex justify-content-center mt-2 chart-container">
    <p>No se encontró ningún resultado.</p>
  </div>
  <div *ngIf="!isLoadingValuesAccount && valuesDisplaysAccount" class="d-flex justify-content-center mt-2 chart-container ps-3">
    <highcharts-chart
      *ngIf="isHighchartsAccount"
      [Highcharts]="HighchartsAccount"
      [constructorType]="chartConstructorAccount"
      [options]="chartOptionsAccount"
      [(update)]="updateFlagAccount"
      [oneToOne]="oneToOneFlagAccount"
      [runOutsideAngular]="runOutsideAngularAccount"
      style="width: 100%; height: 400px; display: block;"></highcharts-chart>
  </div>

  <h5 class="py-2 ms-4">Cantidad de viajes completados por mes</h5>
  <form #fDays="ngForm" (ngSubmit)="getDaysCount(fDays)">
    <div class="ms-4 d-flex flex-row gap-2 mb-2">
      <div class="d-flex flex-column align-items-start gap-1">
        <span class="fw-bold span-date">Inicio</span>
        <input type="date" class="form-control form-date" ngModel name="startingDate">
      </div>
      <div class="d-flex flex-column align-items-start gap-1">
        <span class="span-date fw-bold">Fin</span>
        <input type="date" class="form-control form-date" ngModel name="endingDate">
      </div>
      <button class="btn btn-custom dropdown-margin" type="submit">Buscar</button>
    </div>
  </form>

  <div *ngIf="isLoadingValuesDay && valuesDisplaysDay && !valuesEmptyDay"
    class="d-flex justify-content-center mt-2 chart-container">
    <app-small-spinner></app-small-spinner>
  </div>
  <div *ngIf="valuesEmptyDay" class="d-flex justify-content-center mt-2 chart-container">
    <p>No se encontró ningún resultado.</p>
  </div>
  <div *ngIf="!isLoadingValuesDay && valuesDisplaysDay" class="d-flex justify-content-center mt-2 chart-container ps-3">
    <highcharts-chart
      *ngIf="isHighchartsDay"
      [Highcharts]="HighchartsDay"
      [constructorType]="chartConstructorDay"
      [options]="chartOptionsDay"
      [(update)]="updateFlagDay"
      [oneToOne]="oneToOneFlagDay"
      [runOutsideAngular]="runOutsideAngularDay"
      style="width: 100%; height: 400px; display: block;"></highcharts-chart>
  </div>
</div>
