import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ViewportScroller } from '@angular/common';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs';
import { NotFoundModule } from './pages/not-found/not-found.module';
import { UnauthorizedModule } from './pages/unauthorized/unauthorized.module';
import { SharedModule } from './components/shared.module';
import { AccountsDashboardModule } from './pages/accounts-dashboard/accounts-dashboard.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { LoginModule } from './pages/login/login.module';
import { RecoverPasswordModule } from './pages/recover-password/recover-password.module';
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NotFoundModule,
    UnauthorizedModule,
    SharedModule,
    AccountsDashboardModule,
    DashboardModule,
    LoginModule,
    RecoverPasswordModule,
    HighchartsChartModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setOffset([0, 60]);
    router.events
      .pipe(filter((e: any) => e instanceof Scroll))
      .subscribe((e: Scroll) => {
        if (e.anchor) {
          let anchor = e.anchor;
          setTimeout(() =>
            viewportScroller.scrollToAnchor(anchor)
          );
        } else if (e.position) {
          viewportScroller.scrollToPosition(e.position);
        } else {
          viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }
}
