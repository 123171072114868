<div class="container-login d-flex justify-content-center align-items-center">
  <div class="card">
    <div class="card-body">
      <h5 class="card-title text-center mb-2">Ingresar a Administrador</h5>
      <div class="alert alert-danger d-flex justify-content-between" *ngIf="displayError">
        <span>{{error}}</span>
        <button type="button" class="btn-close text-end" aria-label="Close" (click)="closeError()"></button>
      </div>
      <form class="form-signin" #formData="ngForm" (ngSubmit)="loginFun(formData)">
      <div class="mb-3">
        <label for="correoUsuarioLogin" class="form-label">Email</label>
         <input type="email" id="correoUsuarioLogin" class="form-control" required ngModel name="email">
      </div>
      <div class="mb-3">
        <label for="password-signup" class="form-label">Contraseña</label>
        <div class="input-group">
          <button (click)="passwordFun()" class="btn btn-outline-secondary" type="button"><i class="fa-solid fa-eye"></i></button>
          <input type="password" id="password-signup" class="form-control" required ngModel name="password"
          [type]="showPassword ? 'text' : 'password'">
        </div>
      </div>

      <div class="form-group link-group">
        <a class="login-text  nav-link" style="cursor: pointer;" (click)=" openRecoverPassword()">¿Olvidaste tu contraseña?</a>
      </div>

      <div class="text-center pt-2">
        <button type="submit" class="btn login-button">Iniciar Sesión</button>
      </div>
      </form>
    </div>
  </div>
</div>

<!--<section class="container-fluid row container-login">
  <div class="col-md-12 col-lg-6 bg-login">
    <img class="image-service" src="../../../assets/img/rocket-login.png" alt="login-image">
  </div>
  <div class="col-md-12 col-lg-5  login">
    <h1 class="login-header text-start">Inicia Sesion</h1>
    <div class="d-flex flex-row">
      <p class="nav-link text-register me-2" >¿No tienes cuenta?</p>
      <a routerLink="/registro" class="nav-link login-text">Registrate</a>
    </div>
    <div class="alert alert-danger d-flex justify-content-between" *ngIf="displayError">
      <span>{{error}}</span>
      <button type="button" class="btn-close text-end" aria-label="Close" (click)="closeError()"></button>
    </div>
    <form class="form-signin" #formData="ngForm" (ngSubmit)="loginFun(formData)">
      <div class="mb-3">
        <label for="correoUsuarioLogin" class="form-label">Email</label>
         <input type="email" id="correoUsuarioLogin" class="form-control" required ngModel name="email">
      </div>
      <div class="mb-3">
        <label for="password-signup" class="form-label">Contraseña</label>
        <div class="input-group">
          <button (click)="passwordFun()" class="btn btn-outline-secondary" type="button"><i class="fa-solid fa-eye"></i></button>
          <input type="password" id="password-signup" class="form-control" required ngModel name="password"
          [type]="showPassword ? 'text' : 'password'">
        </div>
      </div>

      <div class="form-group link-group">
        <a class="login-text  nav-link" style="cursor: pointer;" (click)=" openRecoverPassword()">¿Olvidaste tu contraseña?</a>
      </div>

      <div class="text-center pt-2">
        <button type="submit" class="btn login-button">Iniciar Sesión</button>
      </div>
    </form>
  </div>-->

  <!-- Forgot Password Modal -->
  <div  class="modal" tabindex="-1" [ngStyle]="{'display':displayPasswordModal}">
    <div class=" modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header">
          <h1 class="modal-title login-header">¿Olvidaste tu contraseña?</h1>
          <button type="button" class="btn-close"  aria-label="Close" (click)="closePasswordModal()"></button>
        </div>
        <div class="modal-body">
          <p class="text-center login-text  mb-2">Ingrese su correo para que podamos enviarle un enlace para recuperar su cuenta</p>
          <form #recoverData="ngForm" (ngSubmit)="recoverFun(recoverData)">
            <div class="container">
              <div class="alert alert-danger d-flex justify-content-between" *ngIf="displayRecoverPasswordError">
                <span>{{recoverPasswordError}}</span>
                <button type="button" class="btn-close text-end" aria-label="Close" (click)="closeRecoverPasswordError()"></button>
              </div>
              <div class="row mx-3 my-2">
                  <label for="correoUsuarioLogin" class="form-label">Email</label>
                  <input type="email" id="correoUsuarioLogin" class="form-control" required ngModel name="email">
              </div>
              <div class="row d-flex justify-content-center mx-2 mb-2">
                <button type="submit" class="btn login-button">Recuperar cuenta</button>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary"  (click)="closePasswordModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>

  <!--Recover Password Modal Confirmation-->
  <div class="modal" tabindex="-1" [ngStyle]="{'display':displayRecoverPasswordConfirmation}">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title login-header">Le hemos enviado un correo electrónico con un enlace para que recupere su cuenta</h5>
          <button type="button" class="btn-close" aria-label="Close" (click)="closeRecoverPasswordConfirmation()"></button>
        </div>
        <div class="modal-body">
          <p class="login-text">Puede cerrar esta ventana</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary close-confirmation-modal" (click)="closeRecoverPasswordConfirmation()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
