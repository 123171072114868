import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoginService } from '../../pages/login/login.service';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  accountConnected: boolean = false;
  accountId?: string;
  private accountConnectedUsb?:Subscription;

  constructor(
    private loginService: LoginService,
    private cookieService: CookieService,
  ) {}

  ngOnInit(): void {
    this.accountConnectedUsb = this.loginService.account!.subscribe((account) => {
      this.accountConnected = !!account;
    });
  }

  logOut() {
    this.accountConnected = false;
    this.loginService.logOutAccount();
  }

  ngOnDestroy(): void {
    if(this.accountConnectedUsb) {
      this.accountConnectedUsb.unsubscribe();
    }
  }
}

