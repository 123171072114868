<app-navbar></app-navbar>
<section class="container-fluid mt-lg-auto row d-flex justify-content-center" id="container-login">
  <div class="col-md-5 pt-4" id="login">
    <h1 class="pb-4 login-header">Recuperar Contraseña</h1>
    <div class="alert alert-danger d-flex justify-content-between" *ngIf="errorDefined">
      <span> {{error}} </span>
      <button type="button" class="btn-close text-end" aria-label="Close" (click)="closeError()"></button>
    </div>
    <form class="form-signin" #formData="ngForm" (ngSubmit)="recoverPassword(formData)">
      <div class="input-group mb-3 pt-3">
        <button (click)="passwordFun()" class="btn btn-outline-secondary" type="button" id="button-addon1"><i class="fa-solid fa-eye"></i></button>
        <input type="password" id="claveUsuarioLogin" class="form-control" placeholder="Ingrese su nueva contraseña" required ngModel name="newPassword"
        [type]="showPassword ? 'text' : 'password'">
      </div>

      <div class="text-center pt-2">
        <button type="submit" class="btn btn-sm custom-button">Recuperar Contraseña</button>
      </div>
    </form>
  </div>

  <!-- Modal -->
<div  class="modal" tabindex="-1" [ngStyle]="{'display':displayModal}">
  <div class=" modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header header">
        <h1 class="modal-title header-text fs-5" id="exampleModalLabel">Su contraseña fue cambiada exitosamente</h1>
        <button type="button" class="btn-close"  aria-label="Close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        Puede iniciar sesión:
      </div>
      <div class="modal-footer d-flex justify-content-around">
        <button type="button" class="btn btn-sm custom-button" (click)="toLogin()">Ir a Iniciar Sesión</button>
        <button type="button" class="btn btn-secondary"  (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>
</section>
