import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, first, map, take } from 'rxjs';
import { LoginService } from '../../pages/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router, private loginService: LoginService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.loginService.activeRole.pipe(
      first(activeRole => activeRole === 'Admin'),
      map(activeRole => {
        if (activeRole) {
          return true;
        } else {
          this.router.navigate(['unauthorized']);
          return false;
        }
      })
    );
  }

}
