import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountInterface } from '../../shared/interfaces/account.interface';
import { RoleInterface } from '../../shared/interfaces/role.interface';
import { RoleAccountInterface } from '../../shared/interfaces/role-account.interface';
import { AdminService } from '../admin.service';
import { NgForm } from '@angular/forms';
import { AccountRoleInterface } from '../../shared/interfaces/account-role.interface';
import { UpdateAccountInterface } from '../../shared/interfaces/update-account.interface';

@Component({
  selector: 'app-accounts-dashboard',
  templateUrl: './accounts-dashboard.component.html',
  styleUrls: ['./accounts-dashboard.component.scss']
})
export class AccountsDashboardComponent {
  currentAccount?: AccountInterface;
  filteredItems?: AccountInterface[] = [];
  accountUsb?: Subscription;

  roles?: RoleInterface[];
  rolesUsb?: Subscription;
  rolesIds: string[] = [];
  selectedRoles: RoleInterface[] = [];
  rolesNames: string[] = [];
  displayAddedRole:boolean = false;
  displayDeleteRole:boolean = false;
  currentRolesNames: string[] = [];
  rolesServices: RoleAccountInterface[] = [];
  displayErrorRole:boolean = false;
  errorRole:string = '';
  noItems:boolean = false;
  searchText:string = '';
  searchType: string = '';
  currentRoleName?:string;
  displayActivateAccount:boolean = false;
  displayDeactivateAccount:boolean = false;

  showViewModal:string = 'none';
  displayConfirmView:boolean = false;
  errorView:string = '';
  displayErrorView:boolean = false;

  showEditModal:string = 'none';
  displayConfirmEdit:boolean = false;
  errorEdit:string = '';
  displayErrorEdit:boolean = false;

  showDeleteModal:string = 'none';
  displayConfirmDelete:string = 'none';
  errorDelete:string = '';
  displayErrorDelete:boolean = false;

  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number = 0

  //Spinner
  editSpinner:boolean = false;
  createSpinner:boolean = false;
  isMainSpinnerActive: boolean = false;
  imagesToLoad: string[] = [];
  imagesLoaded: number = 0;

  constructor(
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute
    ) {}

  ngOnInit(): void {
    this.loadPageData(this.currentPage, this.itemsPerPage);
    this.rolesUsb = this.adminService.getAllRoles().subscribe(response => {
      this.roles = response;
    })
  }

  loadPageData(page: number, pageSize: number): void {
    this.accountUsb = this.adminService.getAllAccounts(page.toString(), pageSize.toString()).subscribe(response => {
      this.imagesToLoad = []
      this.imagesLoaded = 0;
      this.filteredItems = response.items;
      this.totalItems = response.totalCount;
    }, errorMessage => {
      console.log(errorMessage);
    });
  }

  preloadImagesModal() {
    this.imagesToLoad.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        this.imagesLoaded++;
        if (this.imagesLoaded === this.imagesToLoad.length) {
          this.editSpinner = false;
        }
      };
    });
  }

  changePage(newPage: number, event?: Event): void {
    if (event) {
      event.preventDefault();
    }
    this.currentPage = newPage;
    this.loadPageData(this.currentPage, this.itemsPerPage);
  }

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  get displayedPages(): number[] {
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    let startPage: number;
    let endPage: number;

    if (totalPages <= 4) {
      // Less than 4 total pages, show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // More than 4 total pages, calculate start and end pages
      if (currentPage <= 2) {
        startPage = 1;
        endPage = 4;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 3;
        endPage = totalPages;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 2;
      }
    }

    return Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
  }

    //Searcher
    setSearchType(searchType:string) {
      this.searchType = searchType;
    }

    filterByUsername() {
      this.accountUsb = this.adminService.getAllAccountsByUsername(
        '1', this.itemsPerPage.toString(), this.searchText).subscribe(accounts => {
        this.filteredItems = accounts.items;
        if(this.filteredItems.length === 0) {
          this.noItems = true;
        } else {
          this.noItems = false;
        }

          this.totalItems = accounts.totalCount;
          this.searchText = '';
          this.currentPage = 1;
      }, errorMessage => {
        console.log(errorMessage);
      })
    }

    filterByEmail() {
      this.accountUsb = this.adminService.getAllAccountsByEmail(
        '1', this.itemsPerPage.toString(), this.searchText).subscribe(accounts => {
        this.filteredItems = accounts.items;
        if(this.filteredItems.length === 0) {
          this.noItems = true;
        } else {
          this.noItems = false;
        }

          this.totalItems = accounts.totalCount;
          this.searchText = '';
          this.currentPage = 1;
      }, errorMessage => {
        console.log(errorMessage);
      })
    }

    filterItems(form:NgForm) {
      this.searchText = form.value.searchText.toLowerCase();
        switch (this.searchType) {
          case 'Nombre de usuario':
            this.filterByUsername();
            break;
          case 'Correo electrónico':
            this.filterByEmail();
            break;
          default:
            return ;
        }
          form.reset();
    }

    filterByRole(role: RoleInterface) {
      this.accountUsb = this.adminService.getAccountByRole('1', this.itemsPerPage.toString(), role.roleName).subscribe(services => {
        this.filteredItems = services.items;
        if(this.filteredItems.length === 0) {
          this.noItems = true;
        } else {
          this.noItems = false;
        }
        this.currentRoleName = role.roleName;
        this.totalItems = services.totalCount;
        this.currentPage = 1;
      });
    }

    defaultItems() {
      this.searchType = '';
      this.currentRoleName = undefined;
      this.ngOnInit();
      this.noItems = false;
    }

  //View
  returnAccountRoleNames(account: AccountInterface) {
    const rolesNames:string[] = account.roles!.map(role => {return role.role!.roleName!});
    return rolesNames;
  }

  returnRolesNames() {
    const rolesNames:string[] = this.selectedRoles!.map(role => {return role.roleName!});
    this.rolesNames = rolesNames;
  }

  displayViewModal(account: AccountInterface) {
    this.showViewModal = 'block'
    this.currentAccount = account;
  }

  closeViewModal() {
    this.showViewModal = 'none';
    this.currentAccount = undefined;
  }

  //Edit
  returnCurrentAccountRolesNames() {
    const rolesNames:string[] = this.currentAccount!.roles!.map(role => {return role.role!.roleName!});
    this.currentRolesNames = rolesNames;
  }


  displayEditModal(account: AccountInterface) {
    this.editSpinner = true
    this.showEditModal = 'block'
    this.currentAccount = account;
    this.rolesServices = this.currentAccount.roles;
    this.returnCurrentAccountRolesNames();
    this.selectedRoles = this.currentAccount.roles?.map((role) => role.role);
    this.editSpinner = false;
  }

  closeEditModal() {
    this.showEditModal = 'none';
    this.currentAccount = undefined;
    this.rolesServices = [];
    this.rolesNames = [];
    this.rolesIds = [];
    this.selectedRoles = [];
    this.currentRolesNames = [];
    this.displayAddedRole = false;
    this.displayDeleteRole = false;
    this.displayErrorRole = false;
    this.displayActivateAccount = false;
    this.displayDeactivateAccount = false;
  }

  closeDisplayActivateAccount() {
    this.displayActivateAccount = false;
  }

  activateAccount() {
    this.adminService.activateAccount(this.currentAccount!.accountId.toString()).subscribe(account => {
      this.ngOnInit();
      this.currentAccount = account;
      this.displayActivateAccount = true;
    });
  }

  closeDisplayDeactivateAccount() {
    this.displayDeactivateAccount = false;
  }

  deactivateAccount() {
    this.adminService.deactivateAccount(this.currentAccount!.accountId.toString()).subscribe(account => {
      this.ngOnInit();
      this.currentAccount = account;
      this.displayDeactivateAccount = true;
    });
  }

  closeConfirmEdit() {
    this.displayConfirmEdit = false;
  }

  closeErrorEdit() {
    this.displayErrorEdit = false;
    this.errorEdit = '';
  }

  addRoleId(role: RoleInterface) {
    this.rolesIds.push(role.roleId.toString());
    this.selectedRoles.push(role)
    this.returnRolesNames();
    this.displayDeleteRole = false;
    this.displayAddedRole = true;
  }

  removeRoleId(role: RoleInterface): void {
    this.rolesIds = this.rolesIds.filter((id) => id !== role.roleId.toString());
    this.selectedRoles = this.selectedRoles.filter((role) => role.roleId !== role.roleId);
    this.returnRolesNames();
    this.displayAddedRole = false;
    this.displayDeleteRole = true;
  }

  addRoleToAccount(role: RoleInterface) {
    const roleData: AccountRoleInterface = {roleName: role.roleName}
    this.adminService.addRoleToAccount(this.currentAccount!.accountId.toString(), roleData).subscribe(account => {
      this.ngOnInit();
      this.currentAccount = account;
      this.rolesServices = this.currentAccount.roles;
      this.returnCurrentAccountRolesNames();
      this.displayDeleteRole = false;
      this.displayAddedRole = true;
    }, errorMessage => {
      console.log(errorMessage)
      this.errorRole = errorMessage
      this.displayErrorRole = true;
      this.createSpinner = false;
    })
  }

  closeErrorRole() {
    this.displayErrorRole = false;
    this.errorRole = '';
  }

  removeRoleToAccount(role: RoleAccountInterface) {
    const roleData: AccountRoleInterface = {roleName: role.role.roleName}
    this.adminService.removeRoleOfAccount(this.currentAccount!.accountId.toString(), roleData).subscribe(account => {
      this.ngOnInit();
      this.currentAccount!.roles =
      this.currentAccount!.roles.filter((role) => role.roleAccountId !== role.roleAccountId);
      this.returnCurrentAccountRolesNames();
      this.displayAddedRole = false;
      this.displayDeleteRole = true;
    })
  }

  closeAddRoleConfirmation() {
    this.displayAddedRole = false;
  }

  closeDeleteRoleConfirmation() {
    this.displayDeleteRole = false;
  }

  edit(form:NgForm) {
    this.editSpinner = true;
    const accountData: UpdateAccountInterface = form.value;
    this.adminService.updateAccount(this.currentAccount!.accountId.toString(), accountData).subscribe(account => {
      this.currentAccount = account;
      this.displayConfirmEdit = true;
      this.ngOnInit();
      this.editSpinner = false;
    }, errorMessage => {
      console.log(errorMessage)
      this.errorEdit = errorMessage
      this.displayErrorEdit = true;
      this.editSpinner = false;
    });
  }

  displayDeleteModal(account: AccountInterface) {
    this.showDeleteModal = 'block'
    this.currentAccount = account;
  }

  closeDeleteModal() {
    this.showDeleteModal = 'none';
    this.currentAccount = undefined;
  }

  closeConfirmDelete() {
    this.displayConfirmDelete = 'none';
    this.currentAccount = undefined;
  }

  closeErrorDelete() {
    this.displayErrorDelete = false;
    this.errorDelete = '';
  }

  deleteAccount() {
    this.adminService.deleteAccount(this.currentAccount!.accountId.toString()).subscribe(account => {
      this.ngOnInit();
      this.showDeleteModal = 'none';
      this.displayConfirmDelete = 'block';
    }, errorMessage => {
      console.log(errorMessage)
      this.errorDelete = errorMessage
      this.displayErrorDelete = true;
    });
  }

  ngOnDestroy(): void {
    if(this.accountUsb) {
      this.accountUsb.unsubscribe();
    }

    if(this.rolesUsb) {
      this.rolesUsb.unsubscribe();
    }
  }
}
